<template>
  <div
    ref="chart"
    style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts'

export default {
  props: {
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'procurements_award_sum_by_year': {
      required: true,
      default () {
        return {}
      }
    },
  },
  methods: {
    renderChart (procurementsSumByYear) {
      // console.log(dom.offsetHeight)
      // 取得渲染圖表的dom
      let chartDom = this.$refs.chart
      const chart = echarts.init(chartDom)

      // 年
      let yearList = Object.keys(procurementsSumByYear)
      yearList.sort((a,b) => b-a).reverse()
      if (yearList.length > 5) {
        // yearList.length = 5
        yearList = yearList.slice(yearList.length - 5, yearList.length)
      }

      // 數量
      let countList = yearList.map(year => procurementsSumByYear[year])

      const chartOptions = {
        title: {
          text: `${this.basic.company_name} 近年得標統計`
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['得標總金額'],
          top: '30px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '金額(單位:新臺幣)',
          nameTextStyle: {
            padding: [28,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          name: '年份',
          nameTextStyle: {
            padding: [-8,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          data: yearList
        },
        series: [
          {
            name: '得標總金額',
            type: 'bar',
            data: countList
          }
        ]
      }

      chart.setOption(chartOptions)

      window.onresize = function () {
        chart.resize()
      }
      window.dispatchEvent(new Event('resize'))
    }
  },
  mounted () {
    // 渲染圖表
    if (this.procurements_award_sum_by_year) {
      this.renderChart(this.procurements_award_sum_by_year)
    }
  },
  watch: {
    'procurements_award_sum_by_year': {
      handler (d) {
        if (d) {
          let procurements_award_sum_by_year = JSON.parse(JSON.stringify(d))
          // 渲染圖表
          this.renderChart(procurements_award_sum_by_year)
        }
      },
    },
  }
}
</script>